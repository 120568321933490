import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@/assets/global.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import MathJax from './utils/MathJax'
Vue.prototype.MathJax = MathJax;

Vue.use(ElementUI,{ size: 'small' });
Vue.prototype.$baseURL=process.env.VUE_APP_BASEURL
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
