import Vue from 'vue'
import VueRouter from 'vue-router'
import LayOut from '../views/LayOut'
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'chat',
        component: LayOut,
        redirect: '/chat',
        children: [
            {
                path: 'chat',
                name: 'chat',
                props: true ,
                component: () => import('@/views/chat/Chat')
            },
            {
                path: 'hotCreate',
                name: 'hotCreate',
                component: () => import('@/views/hotCreate/HotCreate')
            },
            {
                path: 'user',
                name: 'user',
                component: () => import('@/views/user/User')
            },
            {
                path: 'pay',
                name: 'pay',
                component: () => import('@/views/user/Pay')
            },
            {
                path: 'JieXi',
                name: 'JieXi',
                component: () => import('@/views/xiaohongshutuji/Xiaohongshutuji')
            },
            {
                path: 'jiance',
                name: 'jiance',
                component: () => import('@/views/jiance/Jiance')
            },
            {
                path: 'autoGenerator',
                name: 'autoGenerator',
                component: () => import('@/views/autoGenerator/AutoGenerator')
            },
            {
                path: 'autoUrlGenerator',
                name: 'autoUrlGenerator',
                component: () => import('@/views/autoUrlGenerator/AutoUrlGenerator')
            },
            {
                path: 'autoWeitoutiaoGenerator',
                name: 'autoWeitoutiaoGenerator',
                component: () => import('@/views/autoWeitoutiaoGenerator/AutoWeitoutiaoGenerator')
            },
            {
                path: 'xiaohongshuUrlGenerator',
                name: 'xiaohongshuoUrlGenerator',
                component: () => import('@/views/xiaohongshuUrlGenerator/XiaohongshuUrlGenerator')
            },
            {
                path: 'baiduUrlGenerator',
                name: 'baiduUrlGenerator',
                component: () => import('@/views/baiduUrlGenerator/BaiduUrlGenerator')
            },
            {
                path: 'csdnUrlGenerator',
                name: 'csdnUrlGenerator',
                component: () => import('@/views/csdnUrlGenerator/CsdnUrlGenerator')
            },
            {
                path: 'hotTool',
                name: 'hotTool',
                component: () => import('@/views/hotTool/HotTool')
            }
    ]
    },
]

const router = new VueRouter({
    // mode: 'history',
    mode: 'hash',
    // base:'/yy/',
    base: process.env.BASE_URL,
    routes
})


export default router
