<template>
  <el-container>
    <el-aside width="300px">
      <div style="display: flex;align-items: center;justify-content: center;padding: 10px">
        <img src="../assets/gpt.jpeg" style="width: 40px;height: 40px;margin-right: 10px">
        <span style="font-size: 18px">智能助手</span>
      </div>
      <div style="padding: 20px;height: 300px;position: relative">
        <div @click="newDialog" style="width: 240px;padding: 8px;border: 1px dashed #76fbb9;text-align: center;border-radius: 10px">
          <span style="font-size: 14px;margin-right: 10px"><i class="el-icon-plus"></i></span>
          <span style="font-size: 14px">创建新对话</span>
        </div>
        <div style="height: 200px;overflow: hidden;overflow-y: scroll;scrollbar-width: none;">
          <div v-for="(item, index) in messages" :key="index" :style="getItemStyle(index)" @click="selectItem(item, index)">
            <div style="font-size: 14px;overflow: hidden;text-overflow: ellipsis;height: 20px">{{ item.content }}</div>
          </div>
        </div>
        <div @click="clearAll"
            style="display: flex;justify-content: center;align-items: center;width: 240px;padding: 8px;border: 1px solid #fff;text-align: center;border-radius: 10px;position: absolute;bottom: 0px">
          <img src="/icons/delete.png" style="width: 18px;height:18px;margin-right: 10px">
          <span style="font-size: 14px">清除所有对话</span>
        </div>
      </div>
      <div style="margin-top: 30px;color: #fff">
        <div
            v-for="(item, index) in menuItems"
            :key="index"
            class="menu4"
            :class="{ active: selectedIndex === index }"
            @click="selectMenu(index)"
        >
          <img
              :src="selectedIndex === index ? item.iconOn : item.icon"
              class="img4"
          />
          <span>{{ item.label }}</span>
        </div>
      </div>
    </el-aside>
    <el-container>
      <el-header>
        <div style="display: flex">
          <div style="width: calc(100vw - 800px)" @click="goUser">
            <div class="notic-bar">
              <img src="/icons/notice.png" class="notice-img"/>
              <div class="notice-bar-container">
                <div class="notice-bar__wrap">
                  <div class="notice-bar__wrap_text">
                    {{ notice }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="header2">
            <img src="/icons/vip.svg" style="width: 30px;height: 30px;margin-right: 10px">
            <span style="font-size: 16px;color: #cf770f" @click="openModal2" v-if="vipStatus===false">开通会员</span>
            <span style="font-size: 16px;color: #cf770f" v-if="vipStatus===true">尊贵VIP会员</span>
          </div>
          <div v-if="chatLoginData.username" style="display:flex;justify-content: center;align-items: center;">
            <img src="../assets/gpt.jpeg" style="width: 40px;height: 40px;margin-right: 10px;border-radius: 50%">
            <span style="margin-right:10px">{{chatLoginData.username}}</span>
            <span style="font-size: 16px;color: #63646f" @click="exitLogin">退出登录</span>
          </div>
          <div class="header2" v-else>
            <img src="../assets/gpt.jpeg" style="width: 40px;height: 40px;margin-right: 10px;border-radius: 50%">
            <span style="font-size: 16px;color: #63646f" @click="openModal">点击登录</span>
          </div>
        </div>
      </el-header>
      <el-main class="chat-container" style="overflow: hidden;overflow-y: scroll;height: calc(100vh - 300px);margin-bottom: 60px">
        <router-view/>
      </el-main>
    </el-container>
    <div v-if="showModal" class="modal-overlay">
      <img src="/icons/close.png" @click="closeModal"
           style="width:26px;height:26px;position: relative;top:-200px;left: 375px">
      <div class="modal-content">
        <img src="/icons/logintitle.png" style="width: 100%;height: 100px;">
        <img :src="qrcode" style="width: 100%;height: 260px">
      </div>
    </div>
    <div v-if="showModal2" class="modal-overlay">
      <img src="/icons/close.png" @click="showModal2=false"
           style="width:26px;height:26px;position: relative;top:-240px;right: -930px">
      <div class="modal-content2" style="display: flex">
       <div>
         <img src="/icons/pc_vip_bg.png" style="width: 200px;height:440px">
       </div>
        <div>
          <div style="text-align: left;margin-left: 20px;margin-top: 10px;font-size: 20px">
            <span>选择套餐会员</span>
          </div>
          <div style="margin-top: 30px;margin-left: 30px;display: flex">
            <div v-for="(item,index) in numberList" :key="item.id" :class="{ selected: selectedIndexNumber === index }" @click="selectItemNumber(index)" style="background-color: #f9f9fa;display: flex;flex-direction: column;padding: 20px 0;width: 160px;border-radius: 10px;">
              <div>
                <span style="font-size: 20px;font-weight: bold">{{ item.dayNum }}</span>
              </div>
             <div style="margin: 10px 0">
               <span style="font-size: 20px;color: red">￥</span>
               <span style="font-size: 28px;color: red">{{ item.money }}</span>
             </div>
              <div style="color: grey">
                <span style="font-size: 13px;">￥</span>
                <span style="font-size: 16px;text-decoration: line-through">{{ item.historyMoney }}</span>
              </div>
            </div>
          </div>
          <div style="display: flex;margin: 50px 20px 20px 20px;text-align: left">
<!--            <div>-->
<!--              <img src="/icons/qcode.png" style="width: 130px;height: 130px">-->
<!--            </div>-->
            <div style="display: flex;flex-direction: column;margin-top: 20px">
              <div style="margin-bottom: 10px">
                <span style="margin-bottom: 10px;font-size: 16px">应付金额:</span>
                <span style="margin-bottom: 10px;font-size: 28px;margin-left: 10px;color: red">￥{{ selectedNumber.money }}</span>
                <span style="margin-bottom: 10px;font-size: 16px;margin-left: 10px;color: red;background-color: #ffe8e8;padding: 10px;border-radius:10px">已优惠￥{{ discountNumer }}</span>
              </div>
              <div style="display: flex;align-items: center;margin-bottom: 10px">
                <img src="/icons/alipay.png" style="width:24px;height: 24px;margin-right: 10px">
                <span style="font-size: 16px">请使用支付宝扫码支付</span>
              </div>
<!--              <span style="font-size: 14px">有效时间10分钟，请尽快支付</span>-->
            </div>
          </div>
          <div>
            <el-button type="primary" style="font-size: 16px" @click="goPay(selectedNumber.money,selectedNumber.dayNum)">去支付</el-button>
          </div>
<!--          <div style="margin-top: 10px;color: red">-->
<!--            提示：付款之前备注你的会员ID，方便客服人员查询快速帮你充值开通-->
<!--          </div>-->
        </div>
      </div>
    </div>
    <div v-if="loading" class="loading-overlay">
      <div class="loading-spinner"></div>
      <span style="font-size: 16px">加载中</span>
    </div>
  </el-container>
</template>
<script>
import '@/css/chat.css'
import request from "@/utils/request";
import Cookies from "js-cookie";
export default {
  data() {
    return {
      vipStatus:false,
      numberList:[],
      selectedIndexNumber: 0,
      selectedNumber: {},
      discountNumer:'',
      id:'',
      chatLoginData:{},
      form:{},
      qrcode: '',
      ticket: '',
      loading: false,
      showModal: false,
      showModal2:false,
      selectedIndex: 0,
      selectedIndex1: 0,
      menuItems: [
        {icon: '/icons/menu_chat.png', iconOn: '/icons/menu_chat_on.png', label: '智能回答'},
        {icon: '/icons/menu_temp.png', iconOn: '/icons/menu_temp_on.png', label: '智能模板'},
        {icon: '/icons/article_tab.png', iconOn: '/icons/article_tab_on.png', label: '自媒体类'},
        {icon: '/icons/menu_user.png', iconOn: '/icons/menu_user_on.png', label: '个人中心'}
      ],
      setTimeoutName:'',
      menuUserName:'',
      notice:'',
      messages:[],
      count:0,
      payqrcode:''
    }
  },
  created() {
    this.chatLoginData=localStorage.getItem("chatLoginData")?JSON.parse(localStorage.getItem("chatLoginData")):{},
    this.getNotice()
   this.checkChatLoginData()
    this.getNumber()
    this.getVipStatus()
  },
  watch: {
    '$route.query.type'(newVal, oldVal) {
      const isActiveType = (val) => val === 'active';
      if (newVal!== oldVal && isActiveType(newVal)) {
        this.getVipStatus();
      }
    },
    '$route.query.vip'(newVal, oldVal) {
      const isVip = (val) => val === '开通会员';
      if (newVal!== oldVal && isVip(newVal)) {
        this.getVipStatus();
      }
    }
  },
  mounted() {
    // 页面加载时从localStorage读取消息
    const savedMessages = JSON.parse(localStorage.getItem('messages'));
    if (savedMessages) {
      this.messages = savedMessages.slice().reverse()
    }
  },
  methods: {
    goPay(money,name){
      this.form.money=money
      this.form.name="会员"+name
      request.post('/alipay/pay',this.form).then(res=>{
        if(res.code==='200'){
          this.payqrcode=res.data
          console.log(res.data)
          window.location.replace(this.payqrcode);
        }
      })
    },
    openModal2(){
      const chatLoginData=localStorage.getItem("chatLoginData")
      if(chatLoginData){
        this.showModal2=true
        this.selectItemNumber(0)
      }else{
        this.showModal=true
      }
    },
    getVipStatus(){
      request.get('/user/selectVipStatusById/'+this.chatLoginData.id).then(res=>{
        if(res.code==='200'){
          this.vipStatus=res.data.vipStatus
        }
      })
    },
    getNumber(){
      request.get('/number/selectAll').then(res=>{
        if(res.code==='200'){

          this.numberList=res.data.sort((a,b)=>a.id-b.id)
        }
      })
    },
    selectItemNumber(index){
      this.selectedIndexNumber = index;
      this.selectedNumber=this.numberList[this.selectedIndexNumber]
      this.discountNumer=this.selectedNumber.historyMoney-this.selectedNumber.money
    },
    goUser(){
      this.loading=true
      setTimeout(()=>{
        if(this.$route.path!=='/user'){
          this.$router.push('/user?notice=vip')
          this.loading=false
        }else if(this.$route.path!=='/user?notice=vip'){
          this.loading=false
        }else {
          this.loading=false
        }
      },1000)
    },
    selectItem(item, index) {
      this.selectedIndex1 = index;
      if (this.$route.fullPath!== `/chat?uuid=${item.uuid}`) {
        this.$router.push('/chat?uuid=' + item.uuid);
      }
    },
    getItemStyle(index) {
      return {
        marginTop: '10px',
        width: '240px',
        padding: '8px',
        border: '1px solid #76fbb9',
        textAlign: 'center',
        borderRadius: '10px',
        backgroundColor: this.selectedIndex1 === index? '#2C2E39' : 'transparent'
      };
    },
    checkChatLoginData() {
      const data = localStorage.getItem('chatLoginData');
      if (data!== this.chatLoginData) {
        if (data === null) {
          console.log('检测删除');
          this.chatLoginData= {}
        }else{
          console.log('检测没有删除');
          this.setTimeoutName=setTimeout(this.checkChatLoginData, 10000); // 每隔 5 秒检查一次
        }
      }
    },
    newDialog() {
      const messages = JSON.parse(localStorage.getItem('messages')) || [];
      this.messages.reverse();
      this.count+=1
      const newMessage = { uuid: Date.now(), sender: "AI", content: "新对话"+this.count};
      this.messages.push(newMessage)
      this.messages.reverse();
    },
    clearAll(){
      localStorage.removeItem("messages")
      this.messages=[]
    },
    getNotice(){
      request.get('/notice/selectAll',{params:{status:1}}).then(res=>{
        if(res.code==='200'){

          this.notice=res.data.length>0?res.data[0].title:''
        }
      })
    },
    exitLogin(){
      localStorage.removeItem("chatLoginData")
      this.chatLoginData={}
      this.$message.success("退出成功")
      this.vipStatus=false
      this.selectMenu(3)
      this.$router.push('/user?type=exit')
    },
    checkLoginStatus() {
      this.form.role="USER"
      this.form.ticket=this.ticket
      request.post('/admin/login', this.form)
          .then(res => {
            if (res.code==='200') {
              localStorage.setItem("chatLoginData",JSON.stringify(res.data))
              this.chatLoginData=res.data
              this.loading=true
              setTimeout(()=>{
                this.loading=false
                this.showModal=false
                this.$message.success("登录成功")
                this.getVipStatus()
                this.$router.push('/user?type=login')
              },1000)
              this.selectMenu(3)
              this.checkChatLoginData()
              ;
            } else if(res.code==='403'){
              this.$message.error(res.msg)
            } else {
              // 登录未成功，继续轮询或给出提示
              console.log('登录未成功，继续检查');
              this.setTimeoutName=setTimeout(this.checkLoginStatus, 5000); // 每隔 5 秒检查一次
            }
          })
          .catch(error => {
            console.error('检查登录状态出错：', error);
          });
    },
    selectMenu(index) {
      this.selectedIndex = index;
      this.menuUserName=this.menuItems[index].label
      if(this.menuUserName==='智能模板'){
        if(this.$route.path!='/hotCreate'){
          this.$router.push('/hotCreate')
        }
      }else if(this.menuUserName==='智能回答'){
        const data=Date.now()
        if(this.$route.path!='/chat?uuid='+data){
          this.$router.push('/chat?uuid='+data)
        }
      }else if(this.menuUserName==='自媒体类'){
        if(this.$route.path!='/hotTool'){
          this.$router.push('/hotTool')
        }
      }else if(this.menuUserName==='个人中心'){
        if(this.$route.path!='/user'){
          this.$router.push('/user')
        }
      }
    },
    closeModal() {
      this.showModal = false
      if (this.setTimeoutName) {
        clearTimeout(this.setTimeoutName);
        this.setTimeoutName = null;
      }
    },
    openModal() {
      this.loading = true
      setTimeout(() => {
        this.loading = false
        this.showModal = true
      }, 1000)
      request.get('/user/qrcode').then(res => {
        if (res.code === '200') {
          this.qrcode = res.data.qrCodeUrl
          this.ticket = res.data.ticket
        }
      })
      this.checkLoginStatus()
    }
  }
}
</script>
<style>
.scrollable-container::-webkit-scrollbar {
  width: 0;
}
</style>
