import axios from 'axios'
import router from "@/router";
import Cookies from 'js-cookie'
import {serverIp} from "./config";
const request = axios.create({
    baseURL:process.env.VUE_APP_BASEURL+':9090/api',
    timeout: 90000
})

// request 拦截器
// 可以自请求发送前对请求做一些处理
// 比如统一加token，对请求参数统一加密
request.interceptors.request.use(config => {
    config.headers['Content-Type'] = 'application/json;charset=utf-8';

    const chatLoginData = localStorage.getItem("chatLoginData")
    if (chatLoginData) {
        // 设置请求头
        config.headers['token'] = JSON.parse(chatLoginData).token
    }
    return config
}, error => {
    return Promise.reject(error)
});

// response 拦截器
// 可以在接口响应后统一处理结果
request.interceptors.response.use(
    response => {
        let res = response.data;
        // 兼容服务端返回的字符串数据
        if (typeof res === 'string') {
            res = res ? JSON.parse(res) : res
        }
        if (res.code === '401') {
            localStorage.removeItem("chatLoginData")
        }
        return res;
    },
    error => {
        console.log('err' + error) // for debug
        return Promise.reject(error)
    }
)


export default request
